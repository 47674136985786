import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AboutUs from '../../assets/images/logo.png';
import Mision from '../../assets/images/mision.png';
import Vision from '../../assets/images/vision.png';
import Abaout from '../../assets/images/sobre.png';
import Mabel from '../../assets/images/picture-t.png';
import Belliard from '../../assets/images/picture-b.png';
import Jennifer from '../../assets/images/Jennifer.png';
import Logo from '../../assets/images/icon.png';
import Evelin from '../../assets/images/Evelin.png';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { PageContact } from '../PageContact';

const PageAbout = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const teamMembers = [
    {
      img: Mabel,
      name: 'Mabel Troncoso',
      role: 'Directora Comercial',
      description:
        'Psicóloga Industrial, egresada de Universidad Iberoamericana, UNIBE obteniendo el grado de Psicología Industrial, Magna Cum Laude, con una Maestria en Dirección Estratégica de Recursos Humanos con doble titulación en las Universidades Pontificia Universidad Católica Madre y Maestra, PUCMM y la Universidad Escuela de Organización Industrial, EOI, España. Certificación de la International Coaching Community en Coach Ejecutivo.'
    },
    {
      img: Belliard,
      name: 'Rafael Belliard',
      role: 'Director de Operaciones',
      description:
        'Ingeniero Industrial, egresado del Instituto Tecnológico de Santo Domingo (INTEC), cuenta con maestrias en Gestión Integrada (MGI) en Calidad, Prevención de Riesgos Laborales y Medio Ambiente (Universidad de A Coruña) & EDAE Alta Escuela de Dirección y Administración de Empresas y una Maestria en Administración de Recursos Humanos (MRHH): PUCMM & EOI. Actualmente cursando estudios de Doctorado en Gestion de Proyectos: Mención Prevención de Riesgos Laborales: Fundación Universitaria Iberoamericana y Universidad Internacional Iberoamericana (Puerto Rico).'
    },
    {
      img: Jennifer,
      name: 'Jennifer Pujols',
      role: 'Consultora Gestión Humana',
      description:
        'Jennifer Pujols. Psicóloga Laboral, egresada se la Universidad Católica de Santo Domingo, UCSD, obteniendo el grado de Lic. Psicología Laboral, cuenta con una Maestria en Gerencia de Recursos Humanos, de la Universidad APEC. Mas de 15 anos en procesos de Gestión estratégica del talento humano. Especializada en procesos de Reclutamiento, Selección y Contratación de personal.'
    },
    {
      img: Evelin,
      name: 'Evelin Torres',
      role: 'Consultora de Seguridad Salud y Medio Ambiente',
      description:
        'Psicologa, con Post Grado en Recursos Humanos y Master en Seguridad y Salud, Coach Profesional, avalada por la Federación Internacional de Coaching (ICF), experiencia en la elaboración de programas de Seguridad y Salud, Asesorias Empresariales, Capacitaciones y docencia universitaria.'
    }
  ];

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          mt: 5,
          mb: 5,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ width: '100%' }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h1"
              textAlign="center"
              color="primary"
              fontWeight="bold"
              fontSize="2.5rem"
              textTransform="uppercase"
            >
              Nosotros
            </Typography>
          </Grid>

          {!isHomePage && (
            <Grid item xs={12} md={10}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 4,
                  width: '100%'
                }}
              >
                <Box
                  component="img"
                  src={AboutUs}
                  alt="B&T Solution Group"
                  sx={{
                    display: { xs: 'none', md: 'block' },
                    width: { md: '50%' },
                    height: 'auto',
                    objectFit: 'contain'
                  }}
                />

                <Box
                  sx={{
                    width: { xs: '100%', md: '50%' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: { xs: 'flex-start', md: 'center' },
                    textAlign: { xs: 'left', md: 'center' }
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '30px',
                      fontWeight: '400',
                      marginTop: 2,
                      marginBottom: 3,
                      px: 3
                    }}
                    variant="h2"
                    color="primary"
                  >
                    B&T SOLUTION GROUP
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      fontFamily: 'sans-serif',
                      fontSize: '18px',
                      textAlign: 'start',
                      marginBottom: 3,
                      px: 3
                    }}
                  >
                    B&T Solution Group surge ante la necesidad de ofrecer
                    soluciones diferenciadas en consultoría integral para la
                    gestión de riesgos laborales, administración de proyectos y
                    gestión del capital humano.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}

          <Grid container justifyContent="center" sx={{ mt: 5 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 3,
                maxWidth: '1200px',
                paddingLeft: { xs: 2, sm: 0 },
                borderRadius: '5rem',
                boxShadow: '0 4px 6px solid rgba(0, 0, 0, 0.1)'
              }}
            >
              <Grid item>
                <Card sx={{ width: '335px', mb: 2 }}>
                  <CardMedia
                    component="img"
                    height="160"
                    src={Mision}
                    alt="Misión"
                  />
                  <CardContent sx={{ height: '210px' }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      textAlign="center"
                      color="primary"
                    >
                      Misión
                    </Typography>
                    <Typography
                      variant="body1"
                      textAlign="start"
                      color="textPrimary"
                    >
                      Contribuir al desarrollo sostenible de las organizaciones
                      mediante la aplicación de soluciones innovadoras en el
                      ámbito de la gestión de los recursos humanos, proyectos,
                      seguridad, salud y medio ambiente.
                    </Typography>
                  </CardContent>
                  {isHomePage && (
                    <CardActions>
                      <Link
                        to="/about"
                        className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                      >
                        Ver más
                      </Link>
                    </CardActions>
                  )}
                </Card>
              </Grid>

              <Grid item>
                <Card sx={{ width: '335px', mb: 2 }}>
                  <CardMedia
                    component="img"
                    height="160"
                    src={Vision}
                    alt="Visión"
                  />
                  <CardContent sx={{ height: '210px' }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      textAlign="center"
                      color="primary"
                    >
                      Visión
                    </Typography>
                    <Typography
                      variant="body1"
                      textAlign="start"
                      color="textPrimary"
                    >
                      Ser la empresa consultora de soluciones innovadoras en
                      gestión de riesgos laborales y administración de recursos
                      humanos, de preferencia en la República Dominicana.
                    </Typography>
                  </CardContent>
                  {isHomePage && (
                    <CardActions>
                      <Link
                        to="/about"
                        className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                      >
                        Ver más
                      </Link>
                    </CardActions>
                  )}
                </Card>
              </Grid>
              <Grid item>
                <Card sx={{ width: '335px', mb: 2 }}>
                  <CardMedia
                    component="img"
                    height="160"
                    src={Abaout}
                    alt="¿Quiénes somos?"
                  />
                  <CardContent sx={{ height: '210px' }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      textAlign="center"
                      color="primary"
                    >
                      ¿Quiénes somos?
                    </Typography>
                    <Typography
                      variant="body1"
                      textAlign="start"
                      color="textPrimary"
                    >
                      Nos dedicamos a brindar consultoría en gestión humana y
                      prevención de riesgos laborales, proporcionando soluciones
                      efectivas que contribuyen al desarrollo sostenible de las
                      organizaciones.
                    </Typography>
                  </CardContent>
                  {isHomePage && (
                    <CardActions>
                      <Link
                        to="/about"
                        className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                      >
                        Ver más
                      </Link>
                    </CardActions>
                  )}
                </Card>
              </Grid>
              {isHomePage && (
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Box
                    component={Link}
                    to="/about"
                    sx={{
                      textDecoration: 'none',
                      color: '#ffffff',
                      backgroundColor: '#483D8B',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '10px 20px',
                      borderRadius: '8px',
                      transition: 'background-color 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#4b0082'
                      }
                    }}
                  >
                    <ArrowForwardIcon sx={{ mr: 1 }} />
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '1.1rem',
                        textAlign: 'center',
                        color: 'inherit'
                      }}
                    >
                      Descubre más acerca de los expertos detrás de B&T Solution
                      Group
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Box>
          </Grid>

          {/* Miembros del equipo */}
          {!isHomePage && (
            <Grid
              container
              justifyContent="center"
              spacing={4}
              alignItems="center"
              mt={5}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    mt: 5,
                    mb: 2
                  }}
                >
                  <Typography
                    variant="h2"
                    textAlign="center"
                    color="primary"
                    fontWeight="bold"
                    fontSize="2.5rem"
                    textTransform="uppercase"
                  >
                    Nuestro equipo
                  </Typography>
                </Box>
              </Grid>
              {teamMembers.map((member, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} md={6} sm={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: { xs: 0, sm: -2 },
                        mt: { xs: -9, sm: -2 },
                        p: 4
                      }}
                    >
                      <img
                        src={member.img}
                        width="100%"
                        style={{ maxWidth: '400px', borderRadius: '8px' }}
                        className="img-fluid"
                        alt={member.name}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6} sm={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        mb: 4,
                        mt: { xs: -10, sm: 4 },
                        p: 4
                      }}
                    >
                      <Box
                        sx={{
                          display: { xs: 'none', sm: 'block' },
                          justifyContent: 'center',
                          alignItems: 'center',
                          mb: 1
                        }}
                      >
                        <img
                          src={Logo}
                          width="100%"
                          style={{ maxWidth: '190px', marginBottom: '20px' }}
                          className="img-fluid"
                          alt="B&T Solution Group"
                        />
                      </Box>

                      <Typography
                        sx={{
                          fontFamily: 'sans-serif',
                          fontSize: {
                            xs: '2.2rem',
                            md: '2.8rem',
                            sm: '2.6rem'
                          },
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          textAlign: 'center',
                          mb: { xs: 1, sm: 3 }
                        }}
                        component="h1"
                        color="primary"
                      >
                        {member.name}
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          width: '100%',
                          maxWidth: '600px',
                          fontSize: '1.7rem',
                          fontWeight: 'bold',
                          lineHeight: 'revert',
                          backgroundColor: '#82729e',
                          textAlign: 'center',
                          color: '#ffffff',
                          fontFamily: 'Quicksand',
                          p: 1,
                          borderRadius: '4px'
                        }}
                      >
                        {member.role}
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{
                          width: '100%',
                          maxWidth: '600px',
                          fontSize: '18px',
                          textAlign: 'center',
                          padding: 4,
                          backgroundColor: '#4b0082',
                          color: '#ffffff',
                          borderRadius: '4px',
                          mt: 2
                        }}
                      >
                        {member.description}
                      </Typography>
                    </Box>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          )}
        </Grid>
      </Box>
      {!isHomePage ? (
        <Box sx={{ backgroundColor: '#f0f0f0', mt: 5, py: 5, px: 2 }}>
          <PageContact />
        </Box>
      ) : null}
    </>
  );
};

export default PageAbout;
