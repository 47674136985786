import React from 'react';
import AppRoute from './routes/AppRoute';

const App = () => {
  return (
    <>
      <AppRoute />
    </>
  );
};

export default App;
