import React from 'react';
import { Container, Box } from '@mui/material';
import { Route, Routes } from 'react-router';
import { PageHome } from '../pages/PageHome';
import { Navbar } from '../components/Navbar';
import { PageAbout } from '../pages/PageAbout';
import { PageServices } from '../pages/PageServices';
import { Footer } from '../components/Footer';
import { PageContact } from '../pages/PageContact';

function AppRoute() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <Navbar />
      <Container
        sx={{
          mt: 5,
          flexGrow: 1,
          minHeight: 'calc(100vh - 200px)'
        }}
        maxWidth={false}
        disableGutters
      >
        <Routes>
          <Route path="/" element={<PageHome />} />
          <Route path="/about" element={<PageAbout />} />
          <Route path="/services" element={<PageServices />} />
          <Route path="/contact" element={<PageContact />} />
        </Routes>
      </Container>
      <Footer />
    </Box>
  );
}

export default AppRoute;
