import React from 'react';
import ImagenLogo from './../../assets/images/logo.png';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import PageAbout from '../PageAbout/PageAbout';
import PageServices from '../PageServices/PageServices';
import { Form } from '../../components/Form';
import { PageContact } from '../PageContact';

const PageHome = () => {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 'calc(100vh - 200px)',
          backgroundColor: '#ffffff',
          padding: 0
        }}
      >
        <Grid container justifyContent="center">
          <Grid xs={12} sm={10} md={8} lg={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={ImagenLogo}
                alt="B&T Solution Group"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  maxHeight: '875px'
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: '#f0f0f0', py: 5, px: 2 }}>
        <PageAbout />
      </Box>
      <Box sx={{ backgroundColor: '#ffffff', py: 5, px: 2 }}>
        <PageServices />
      </Box>
      <Box sx={{ backgroundColor: '#ffffff', py: 5, px: 2 }}>
        <Form />
      </Box>
      <Box sx={{ backgroundColor: '#f0f0f0', py: 5, px: 2 }}>
        <PageContact />
      </Box>
    </>
  );
};

export default PageHome;
