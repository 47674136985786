import React, { useState } from 'react';
import Logo from '../../assets/images/logo.png';
import SendIcon from '@mui/icons-material/Send';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

const Form = () => {
  const [formulario, setFormulario] = useState({
    nombre: '',
    correo: '',
    mensaje: ''
  });

  const handleChange = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_nwo0f3p',
        'template_256jqsk',
        e.target,
        'koEQW84JyDOVGR0tr'
      )
      .then(
        (result) => {
          if (result.text) {
            Swal.fire(
              '¡Mensaje enviado!',
              '¡Su mensaje ha sido enviado correctamente!',
              'success'
            );
            e.target.reset();
          }
        },
        (error) => {
          if (error.text) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: '¡Ha ocurrido un error!'
            });
          }
        }
      );
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          p: 2,
          mt: 1
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'flex'
                },
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 14,
                mb: 20
              }}
            >
              <img
                src={Logo}
                width={500}
                className="img-fluid"
                alt="B&T Solution Group"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 5,
                mb: 1
              }}
            >
              <form onSubmit={handleOnSubmit}>
                <Typography
                  sx={{
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    fontSize: {
                      xs: '2rem',
                      sm: '2.3rem'
                    },
                    mb: 5
                  }}
                  variant="h2"
                  color="primary"
                >
                  Escríbenos Aquí
                </Typography>

                <TextField
                  sx={{
                    mb: 3
                  }}
                  id="nombre"
                  type="text"
                  name="nombre"
                  label="Nombre"
                  onChange={handleChange}
                  autoComplete="name"
                  fullWidth
                  required
                />
                <TextField
                  sx={{
                    mb: 3
                  }}
                  type="email"
                  name="correo"
                  id="correo"
                  label="Correo Electrónico"
                  onChange={handleChange}
                  autoComplete="email"
                  fullWidth
                  required
                />
                <TextField
                  sx={{
                    height: '200px',
                    mb: 1
                  }}
                  type="text"
                  name="mensaje"
                  id="mensaje"
                  label="Mensaje"
                  rows={6}
                  multiline
                  fullWidth
                  onChange={handleChange}
                  autoComplete="off"
                  required
                />
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ height: 60 }}
                  startIcon={<SendIcon />}
                >
                  Enviar
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Form;
