import React from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Consulta from '../../assets/images/consult.png';
import Prevencion from '../../assets/images/prevencion.png';
import Project from '../../assets/images/project.png';
import { PageContact } from '../PageContact';

const PageServices = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const servicesCards = [
    {
      img: Consulta,
      title: 'Consultoría en la gestión integral humana',
      description:
        'Atracción de Talento, Capacitación, Entrenamientos, Desarrollo de Personal, Manual de Políticas y Procedimientos, Manual de Descripciones de Puestos, Planes de Sucesión, Relaciones Laborales y Coaching Ejecutivo. Nuestro compromiso es fortalecer los recursos humanos de su organización.'
    },
    {
      img: Prevencion,
      title: 'Consultoría en gestión de prevención de riesgos',
      description:
        'Implementación de estrategias para Seguridad, Salud Organizacional y Medio Ambiente, con asesoría en normas y regulaciones nacionales e internacionales. Nos enfocamos en la prevención integral para un entorno laboral seguro y saludable.'
    },
    {
      img: Project,
      title: 'Gestión de proyectos',
      description:
        'Planificación, coordinación y supervisión en la ejecución de proyectos, desde la concepción hasta su finalización. Optimizamos la eficiencia, reducimos riesgos y aseguramos el cumplimiento de los objetivos específicos de cada proyecto, garantizando resultados de calidad.'
    }
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        justifyContent: 'center',
        mt: 2,
        mb: 0,
        overflowX: 'hidden'
      }}
    >
      <Typography
        variant="h2"
        textAlign="center"
        color="primary"
        fontWeight="bold"
        fontSize="2.5rem"
        textTransform="uppercase"
        mt={5}
        mb={3}
      >
        Servicios
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {servicesCards.map((card, index) => (
          <Grid item key={index}>
            <Card
              sx={{
                width: '335px',
                mb: 2,
                boxShadow: '0px 4px 6px solid #000000'
              }}
            >
              <CardMedia
                component="img"
                height="210"
                src={card.img}
                alt={card.title}
              />
              <CardContent sx={{ height: '300px' }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  textAlign="start"
                  color="primary"
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="start"
                  color="textPrimary"
                >
                  {card.description}
                </Typography>
              </CardContent>
              {isHomePage && (
                <CardActions>
                  <Link
                    to="/services"
                    className="link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  >
                    Ver más
                  </Link>
                </CardActions>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>

      {!isHomePage ? (
        <Box sx={{ backgroundColor: '#f0f0f0', mt: 5, py: 5, px: 2 }}>
          <PageContact />
        </Box>
      ) : null}
    </Box>
  );
};

export default PageServices;
