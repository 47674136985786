import React from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, Typography } from '@mui/material';
import { Form } from '../../components/Form';

const PageContact = () => {
  const location = useLocation();

  const InstaIcon = (props) => <InstagramIcon {...props} />;

  const WhatIcon = (props) => <WhatsAppIcon {...props} />;

  const LinkIcon = (props) => <LinkedInIcon {...props} />;

  return (
    <>
      <Box sx={{ overflowX: 'hidden' }}>
        {location.pathname === '/contact' && (
          <Box sx={{ backgroundColor: '#ffffff', minHeight: '65vh' }}>
            <Form />
          </Box>
        )}
        <Box sx={{ mt: 0, mb: 5, minHeight: '50vh' }}>
          <Grid container spacing={4}>
            <Grid xs={12}>
              <Box>
                <Typography
                  variant="h2"
                  textAlign="center"
                  color="primary"
                  fontWeight="bold"
                  fontSize={{ xs: '2rem', md: '2.5rem' }}
                  textTransform="uppercase"
                  mt={5}
                  mb={5}
                >
                  Contáctanos
                </Typography>
              </Box>
            </Grid>

            <Grid xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflowX: 'hidden',
                  paddingBottom: 5
                }}
              >
                <Typography
                  variant="h2"
                  fontSize={{ xs: '24px', md: '26px' }}
                  fontWeight="bold"
                  color="primary"
                  mt={2}
                  mb={1}
                >
                  Correo electrónico
                </Typography>
                <Typography
                  variant="h2"
                  fontSize={{ xs: '20px', md: '20px' }}
                  fontWeight="400"
                  color="#303030"
                >
                  mabel.troncoso@btsolutiongrp.com
                </Typography>
                <Typography
                  variant="h2"
                  fontSize={{ xs: '20px', md: '20px' }}
                  fontWeight="400"
                  color="#303030"
                >
                  rafael.belliard@btsolutiongrp.com
                </Typography>

                <Typography
                  variant="h2"
                  fontSize={{ xs: '24px', md: '26px' }}
                  fontWeight="bold"
                  color="primary"
                  mt={3}
                >
                  Teléfono
                </Typography>
                <Typography
                  variant="h2"
                  fontSize={{ xs: '20px', md: '20px' }}
                  fontWeight="400"
                  color="#303030"
                  mt={1}
                >
                  809-884-6375
                </Typography>

                <Typography
                  variant="h2"
                  fontSize={{ xs: '24px', md: '26px' }}
                  fontWeight="bold"
                  color="primary"
                  mt={3}
                >
                  Dirección
                </Typography>
                <Typography
                  variant="h2"
                  fontSize={{ xs: '20px', md: '20px' }}
                  fontWeight="400"
                  color="#303030"
                  textAlign="center"
                  mt={1}
                >
                  Santo Domingo, Distrito Nacional, República Dominicana.
                </Typography>
              </Box>
            </Grid>

            <Grid xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflowX: 'hidden',
                  paddingBottom: 5
                }}
              >
                <Typography
                  variant="h2"
                  fontSize={{ xs: '24px', md: '26px' }}
                  fontWeight="bold"
                  color="primary"
                  mt={2}
                  mb={1}
                >
                  Redes Sociales
                </Typography>
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: 2,
                    mt: 2
                  }}
                >
                  <a
                    href="https://www.instagram.com/btsolutiongroup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstaIcon
                      sx={{
                        color: '#E4405F',
                        fontSize: { xs: 60, md: 60 },
                        transition: 'transform 0.3s, color 0.3s',
                        '&:hover': {
                          transform: 'scale(1.2)',
                          color: '#9c3b5b'
                        }
                      }}
                    />
                  </a>
                  <a
                    href="https://bit.ly/BTSolutionGroup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <WhatIcon
                      sx={{
                        color: '#14a412',
                        fontSize: { xs: 60, md: 60 },
                        transition: 'transform 0.3s, color 0.3s',
                        '&:hover': {
                          transform: 'scale(1.2)',
                          color: '#2d9a2d'
                        }
                      }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/bt-solutiongroup-4b178210a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkIcon
                      sx={{
                        color: '#0f46a5',
                        fontSize: { xs: 60, md: 60 },
                        transition: 'transform 0.3s, color 0.3s',
                        '&:hover': {
                          transform: 'scale(1.2)',
                          color: '#1e65b8'
                        }
                      }}
                    />
                  </a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PageContact;
